// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;



document.addEventListener("DOMContentLoaded", function() {
    // Get the input element by its id
    var inputElement = document.getElementById("siteSearch");
  
    // Change the placeholder text
    inputElement.placeholder = "Search useful links";
  
    // Change the placeholder opacity
    inputElement.style.opacity = "0.7";

    if ($(".our-mission").length) {
        $(".our-mission .items").slick({
            fade: true,
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $(".our-mission .prev-slide"),
            nextArrow: $(".our-mission .next-slide"),
        });
   
    }


         // Create slider nav for slider
      // Create a ul element with class "slider-nav"
      var divElement = $('<ul></ul>').addClass('slider-nav-custom');
        
      // Create the first list item with class "prev-slide slick-arrow" and append it to the ul
      var listItem1 = $('<li></li>').addClass('prev-slide slick-arrow');
      divElement.append(listItem1);
      
      // Create the second list item with class "next-slide slick-arrow" and append it to the ul
      var listItem2 = $('<li></li>').addClass('next-slide slick-arrow');
      divElement.append(listItem2);
      
      // Append the ul to the element with class "journeyoffthestreetsFeed"
      $('.journeyoffthestreetsFeed').append(divElement);

          
   


    if ($(".journeyoffthestreetsFeed .feedList").length) {
        $(".journeyoffthestreetsFeed .feedList").slick({
            fade: true,
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $(".journeyoffthestreetsFeed .slider-nav-custom .prev-slide"),
            nextArrow: $(".journeyoffthestreetsFeed .slider-nav-custom .next-slide"),
        });
   
    }








         //add button to each slide article 
         $(".homeFeed .feedItem").each(function () {
            const href = $(this).children("a").attr("href");
            const carouselDetail = $(this).find(".feedItemDetailsWrapper");
    
            $(carouselDetail).append(`<a href="${href}" class="button tertiary-btn-arrow-small">Read More </a>`)
        });


          //add button to each slide article 
          $(".listedItem.listedPost").each(function () {
            const href = $(this).children("a").attr("href");
            const carouselDetail = $(this).find(".listedPostText");
    
            $(carouselDetail).append(`<a href="${href}" class="button tertiary-btn-arrow-small">Read More </a>`)
        });

         



$(".homeFeedBox2 ").prependTo("#story_cta_one");

$(".homeFeedBox1").prependTo("#funraise_cta_one");

$(".homeFeedBox3").appendTo(".homeBox6");



    
  });

// task - gethelp button not working
$("a.button.buttonGethelp").click(function() {
    $('html,body').animate({
        scrollTop: $("section.homeFeature.homeFeatureSnippets.post589262c25b4f482a91311ae5d449cebe.homefeaturecategory-homeboxfullwidth.homeBox4.in-view").offset().top},
        'slow');
});


// Task 3 lines - clone read more button and move to next div
    $('#carouselSlides .carouselSlide').each(function() {
        // Get the container element inside the current slide
        var container = $(this).find('.carouselSlideSummary');
      
        // Get the read more element inside the container
        var readmore = container.find('.readMore.carouselSlideReadMore');
      
        // Move the read more outside, after the container in the DOM
        container.after(readmore);
      });



